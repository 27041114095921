import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import Icon from "../../../../../global/images/icons/Icon";

import { SetViewContext } from "../.././PortalContext";
import { activateIntercom, textTruncate } from "../../../../../utils/functions";

import "./PortraitCards.scss";

const dateOptions = { month: "2-digit", day: "2-digit" };
const maxStringLength = 30;
const returnProperTooltip = str => (str.length > maxStringLength ? str : "");

const PortraitBlankCard = ({ proper, editFn, isProduct = false }) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <>
        {isProduct && <div
          className='Portraits__Card PortraitBlank'
          onClick={() =>
            // setView({
            //   ...view,
            //   component: `${proper}Edit`,
            // })
            window.open('https://calendly.com/HARPERNOIZ', '_blank')
          }
        >
          <div className='Portraits__Blank_Icon'>{Icon({ color: "white", name: "plus" })}</div>
          <div className='Portraits__Blank_Text'>Add New {proper}</div>
        </div>}
        {!isProduct && <div
          className='Portraits__Card PortraitBlank'
          onClick={() => activateIntercom()}>
          <div className='Portraits__Blank_Icon'>{Icon({ color: "white", name: "plus" })}</div>
          <div className='Portraits__Blank_Text'>Add New {proper}</div>
        </div>}
      </>
    )}
  </SetViewContext.Consumer>
);

const PortraitCard = ({
  item: { id, img, name, quests, startDateTime, endDateTime, warnings = [], },
  type,
  proper,
  detailFn,
}) => (
  <SetViewContext.Consumer>
    {({ setView, view }) => (
      <div className='Portraits__Card PortraitCard'>
        <div className='Portraits__Card_Image'>
          {
            !!warnings.length &&
            <Tooltip title={`Missing ${warnings.join(', ')} image${warnings.length > 1 ? 's' : ''}. Please contact an admin to provide the missing image${warnings.length > 1 ? 's' : ''}.`} placement='top'>
              <div className='Portraits__Card_Warning'>
                <Icon name='warning' color="#FFDBBB" />
              </div>
            </Tooltip>
          }
          <img src={img} alt={name} />
        </div>
        <div className='Portraits__Card_Details'>
          <Tooltip title={returnProperTooltip(name)} placement='top'>
            <span className='Portraits__Card_Title'>
              {textTruncate(name || "", maxStringLength)}
            </span>
          </Tooltip>
          <span className='Portraits__Card_Subtitle'>
            {type === "products" && (
              <>
                <div className='Portraits__Card_Subtitle_Icon'>
                  {Icon({ color: "white", name: "scroll" })}
                </div>
                &nbsp;{quests.length} QUEST{quests.length !== 1 && "S"}
              </>
            )}
            {type === "quests" && (
              <>
                {new Date(startDateTime).toLocaleDateString("en-US", dateOptions)} -{" "}
                {new Date(endDateTime).toLocaleDateString("en-US", dateOptions)}
              </>
            )}
          </span>
        </div>
        <div className='Portraits__Card_View' onClick={() => setView({ ...view, ...detailFn(id) })}>
          View {proper}
        </div>
      </div>
    )}
  </SetViewContext.Consumer>
);

const PortraitCards = props => {
  const { items, viewAllFn = () => null, showViewAll = true, auth, ...rest } = props;
  const content = [];
  const isProduct = props.type === "products" || props.type === "quests";
  items?.forEach(item => content.push(<PortraitCard key={item.id} item={item} {...rest} />));

  content.push(
    <PortraitBlankCard key={'blank-key-card'} proper={rest.proper} editFn={rest.editFn} isProduct={isProduct} />
  );

  return (
    <div className='Portraits'>
      <div className='Portraits__Title'>
        <h2>{rest.proper}s</h2>
        {showViewAll && (
          <SetViewContext.Consumer>
            {({ setView, view }) => (
              <div
                className='Portraits__Title_View_All'
                onClick={() => setView(viewAllFn(rest.proper, view))}>
                <span>View All</span>
                <i className='fa fa-chevron-right' />
              </div>
            )}
          </SetViewContext.Consumer>
        )}
      </div>
      <div className='Portraits__List'>{content}</div>
    </div>
  );
};

export default PortraitCards;
