import React from "react";
import { Link } from "react-router";

import defaultAvatar from "../../../images/avatars/defaultSpaceman.png";
import Icon from "../../../global/images/icons/Icon";

import { defaultBaseView } from "./PortalContext";
import { ComingSoon } from "./mvp/ComingSoon";

import "./PortalNav.scss";

const Splotch = () => (
  <div className='PortalNav__Tab_Icon_Splotch'>
    <Icon color="rgb(144, 64, 250)" name="splotch" />
  </div>
);

const TabIcon = ({ setTab, icon, active = false }) => (
  <div className='PortalNav__Tab_Icon' onClick={setTab}>
    {active ? <Splotch /> : null}
    <div className='PortalNav__Tab_Icon_SVG'>
      <Icon color="white" name={icon} />
    </div>
  </div>
);

const UserProfile = ({ user }) => (
  <div className='PortalNav__Tab_UserProfile'>
    <img
      className='PortalNav__Tab_UserProfile_Img'
      src={user?.avatar || user?.connectedAccounts?.twitch?.profile_image_url || defaultAvatar}
      alt={user?.username}
      onClick={() => window.location.replace('/portal?component=Profile')}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = defaultAvatar;
      }}
    />
    <span className='PortalNav__Tab_UserProfile_Hello'>Hello</span>
    <span className='PortalNav__Tab_UserProfile_Username'>{user?.username}</span>
  </div>
);

const Logout = () => (
  <Link to='/logout'>
    <div className='PortalNav__Tab_Logout'>
      <Icon color="white" name="logout" />
      &nbsp;<span className='PortalNav__Tab_Logout_Text'>LOG OUT</span>
    </div>
  </Link>
);

const PortalNav = props => {
  const {
    auth: { token, user },
    getBrandGame,
    getBrandQuest,
    navDict,
    view,
    setView,
  } = props;

  return (
    <div className='PortalNav_Wrapper'>
      <div className='PortalNav'>
        {navDict.map(({ icon, tabName, mvp }) => {
          // console.log(mvp, tabName, 'mvp')
          const theTab = (
            <TabIcon
              active={view?.tab === tabName}
              icon={icon}
              key={tabName}
              setTab={() => {
                setView({ ...defaultBaseView, tab: tabName });
                getBrandGame({ statusGame: 'all' }, token);
                getBrandQuest({ statusQuest: 'all', statusGame: 'all', pageSize: 0 }, token);
              }}
            />
          );
          return !mvp ? <ComingSoon key={`${tabName}-comingsoon`} children={theTab} /> : theTab;
        })}
        <UserProfile user={user} />
        <Logout />
      </div>
    </div>
  );
};

export default PortalNav;
